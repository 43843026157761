import React, { Fragment, useEffect } from 'react';
import { Card, Col, ListGroup, ListGroupItem, Row, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import Message from '../components/Message';
import Loader from '../components/Loader';
import { useNavigate } from 'react-router-dom';
import { completeCheck, getCheckDetails, payCheck } from '../actions/checkActions';
import Moment from 'react-moment';

const CheckScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const { loading, error, check } = useSelector((state) => state.checkDetails);
  const { success } = useSelector((state) => state.checkPay)
  const { success: successComplete } = useSelector((state) => state.checkComplete)

  const { userInfo } = useSelector((state) => state.userLogin);

  useEffect(() => {
    if (!userInfo) {
      navigate('/login');
    } else {
      dispatch(getCheckDetails(id));

    }
  }, [userInfo, navigate, dispatch, id, success, successComplete]);

  const updateToCompleted = () => {

    dispatch(completeCheck(check))
  }

  const updateToPaid = () => {
    dispatch(payCheck(check))
  }

  return (
    <>
      <Link to='/checks' className='btn btn-info my-3'>
        Go Back
      </Link>
      <h1>Check Details</h1>

      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <Fragment>

          <Row>

            <Col xs={12} md={6}>
              {check.type === "Address" ? (
                <Card>
                  <Card.Body>
                    <Card.Title className='text-dark'>
                      Address Check
                    </Card.Title>
                    <Card.Text>
                      <ListGroup>
                        <ListGroupItem>
                          <p>
                            <span className='bold'>Candidate Name: </span>
                            <span>
                              {
                                check.CandidateName}
                            </span>
                          </p>
                        </ListGroupItem>
                        <ListGroupItem>
                          <p>
                            <span className='bold'>Address: </span>{' '}
                            <span>
                              {
                                check.Address}
                            </span>
                          </p>
                        </ListGroupItem>
                      </ListGroup>

                    </Card.Text>
                  </Card.Body>
                </Card>
              ) : check.type === "Guarantor" ? (
                <Card>
                  <Card.Body>
                    <Card.Title className='text-dark'>
                      Guarantor Check
                    </Card.Title>
                    <Card.Text>
                      <ListGroup>
                        <ListGroupItem>
                          <p>
                            <span className='bold'>Candidate Name: </span>
                            <span>
                              {
                                check.CandidateName}
                            </span>
                          </p>
                        </ListGroupItem>
                        <ListGroupItem>
                          <p>
                            <span className='bold'>Guarantor Name:</span>{' '}
                            <span>
                              {
                                check.GuarantorName}
                            </span>
                          </p>
                        </ListGroupItem>
                        <ListGroupItem>
                          <p>
                            <span className='bold'>Guarantor Email:</span>{' '}
                            <span>
                              {check.GuarantorEmail}
                            </span>
                          </p>
                        </ListGroupItem>
                        <ListGroupItem>
                          <p>
                            <span className='bold'>Guarantor Phone:</span>{' '}
                            <span>
                              {check.GuarantorPhone}
                            </span>
                          </p>
                        </ListGroupItem>

                        <ListGroupItem>
                          <p>
                            <span className='bold'>Guarantor Address:</span>{' '}
                            <span>
                              {check.GuarantorAddress}
                            </span>
                          </p>
                        </ListGroupItem>
                      </ListGroup>
                    </Card.Text>
                  </Card.Body>
                </Card>
              ) : check.type === "Reference" ? (
                <Card>
                  <Card.Body>
                    <Card.Title className='text-dark'>Reference Check</Card.Title>
                    <Card.Text>
                      <ListGroup>
                        <ListGroupItem>
                          <p>
                            <span className='bold'>Candidate Name: </span>
                            <span>
                              {
                                check.CandidateName}
                            </span>
                          </p>
                        </ListGroupItem>
                        <ListGroupItem>
                          <p>
                            <span className='bold'>Reference Name:</span>{' '}
                            <span>
                              {check.ReferenceName}
                            </span>
                          </p>
                        </ListGroupItem>
                        <ListGroupItem>
                          <p>
                            <span className='bold'>Reference Email: </span>
                            <span>
                              {check.ReferenceEmail}
                            </span>
                          </p>
                        </ListGroupItem>
                        <ListGroupItem>
                          <p>
                            <span className='bold'>Reference Phone:</span>{' '}
                            <span>
                              {check.ReferencePhone}
                            </span>
                          </p>
                        </ListGroupItem>
                        <ListGroupItem>
                          <p>
                            <span className='bold'>Reference Address:</span>{' '}
                            <span>
                              {check.ReferenceAddress}
                            </span>
                          </p>
                        </ListGroupItem>

                      </ListGroup>
                    </Card.Text>
                  </Card.Body>
                </Card>
              ) : check.type === "PreviousEmployer" ? (
                <Card>
                  <Card.Body>
                    <Card.Title className='text-dark'>
                      Previous Employer Check
                    </Card.Title>
                    <Card.Text>
                      <ListGroup>
                        <ListGroupItem>
                          <p>
                            <span className='bold'>Candidate Name: </span>
                            <span>
                              {
                                check.CandidateName}
                            </span>
                          </p>
                        </ListGroupItem>
                        <ListGroupItem>
                          <p>
                            <span className='bold'>Previous Employer Name:</span>{' '}
                            <span>
                              {check.PrevEmpName}
                            </span>
                          </p>
                        </ListGroupItem>
                        <ListGroupItem>
                          <p>
                            <span className='bold'>
                              Previous Employer Address:
                            </span>{' '}
                            <span>
                              {check.PrevEmpAddress}
                            </span>
                          </p>
                        </ListGroupItem>
                        <ListGroupItem>
                          <p>
                            <span className='bold'>
                              Previous Employer HR Email:
                            </span>{' '}
                            <span>
                              {check.PrevEmpEmail}
                            </span>
                          </p>
                        </ListGroupItem>

                        <ListGroupItem>
                          <p>
                            <span className='bold'>
                              Previous Employer HR Phone:
                            </span>{' '}
                            <span>
                              {check.PrevEmpPhone}
                            </span>
                          </p>
                        </ListGroupItem>

                      </ListGroup>
                    </Card.Text>
                  </Card.Body>
                </Card>
              ) : check.type === "DegreeLocal" ? (
                <Card>
                  <Card.Body>
                    <Card.Title className='text-dark'>
                      Local Degree
                    </Card.Title>
                    <Card.Text>
                      <ListGroup>
                        <ListGroupItem>
                          <p>
                            <span className='bold'>Candidate Name: </span>
                            <span>
                              {
                                check.CandidateName}
                            </span>
                          </p>
                        </ListGroupItem>
                        <ListGroupItem>
                          <p>
                            <span className='bold'>Local Degree: </span>{' '}
                            <span>
                              <Link to={check.DegreeCert} >View File</Link>

                            </span>
                          </p>
                        </ListGroupItem>
                      </ListGroup>

                    </Card.Text>
                  </Card.Body>
                </Card>
              ) : check.type === "DegreeForeign" ? (
                <Card>
                  <Card.Body>
                    <Card.Title className='text-dark'>
                      Foreign Degree Check
                    </Card.Title>
                    <Card.Text>
                      <ListGroup>
                        <ListGroupItem>
                          <p>
                            <span className='bold'>Candidate Name: </span>
                            <span>
                              {
                                check.CandidateName}
                            </span>
                          </p>
                        </ListGroupItem>
                        <ListGroupItem>
                          <p>
                            <span className='bold'>Foreign Degree: </span>{' '}
                            <span>
                              <Link to={check.DegreeCert} >View File</Link>
                            </span>
                          </p>
                        </ListGroupItem>
                        <ListGroupItem>
                          <p>
                            <span className='bold'>Letter Of Authority: </span>{' '}
                            <span>
                              <Link to={check.LetterOfAuthority} >View File</Link>
                            </span>
                          </p>
                        </ListGroupItem>
                      </ListGroup>

                    </Card.Text>
                  </Card.Body>
                </Card>
              ) : check.type === "Tradetest" ? (
                <Card>
                  <Card.Body>
                    <Card.Title className='text-dark'>
                      Tradetest Check
                    </Card.Title>
                    <Card.Text>
                      <ListGroup>
                        <ListGroupItem>
                          <p>
                            <span className='bold'>Candidate Name: </span>
                            <span>
                              {
                                check.CandidateName}
                            </span>
                          </p>
                        </ListGroupItem>
                        <ListGroupItem>
                          <p>
                            <span className='bold'>Tradetest: </span>{' '}
                            <span>
                              <Link to={check.TradetestCert} >View File</Link>
                            </span>
                          </p>
                        </ListGroupItem>
                      </ListGroup>

                    </Card.Text>
                  </Card.Body>
                </Card>
              ) : check.type === "NYSC" ? (
                <Card>
                  <Card.Body>
                    <Card.Title className='text-dark'>
                      NYSC Check
                    </Card.Title>
                    <Card.Text>
                      <ListGroup>
                        <ListGroupItem>
                          <p>
                            <span className='bold'>Candidate Name: </span>
                            <span>
                              {
                                check.CandidateName}
                            </span>
                          </p>
                        </ListGroupItem>
                        <ListGroupItem>
                          <p>
                            <span className='bold'>NYSC: </span>{' '}
                            <span>
                              <Link to={check.NYSCCert} >View File</Link>
                            </span>
                          </p>
                        </ListGroupItem>
                      </ListGroup>

                    </Card.Text>
                  </Card.Body>
                </Card>
              ) : check.type === "Credit" ? (
                <Card>
                  <Card.Body>
                    <Card.Title className='text-dark'>
                      Credit Check
                    </Card.Title>
                    <Card.Text>
                      <ListGroup>
                        <ListGroupItem>
                          <p>
                            <span className='bold'>Candidate Name: </span>
                            <span>
                              {
                                check.CandidateName}
                            </span>
                          </p>
                        </ListGroupItem>
                        <ListGroupItem>
                          <p>
                            <span className='bold'>BVN: </span>{' '}
                            <span>
                              {
                                check.BVN}
                            </span>
                          </p>
                        </ListGroupItem>
                      </ListGroup>

                    </Card.Text>
                  </Card.Body>
                </Card>
              ) : check.type === "ProfessionalLicense" ? (
                <Card>
                  <Card.Body>
                    <Card.Title className='text-dark'>
                      Professional License Check
                    </Card.Title>
                    <Card.Text>
                      <ListGroup>
                        <ListGroupItem>
                          <p>
                            <span className='bold'>Candidate Name: </span>
                            <span>
                              {
                                check.CandidateName}
                            </span>
                          </p>
                        </ListGroupItem>
                        <ListGroupItem>
                          <p>
                            <span className='bold'>Professional License: </span>{' '}
                            <span>
                              <Link to={check.ProfessionalLicenseCert} >View File</Link>
                            </span>
                          </p>
                        </ListGroupItem>
                      </ListGroup>

                    </Card.Text>
                  </Card.Body>
                </Card>
              ) : check.type === "OLevel" ? (
                <Card>
                  <Card.Body>
                    <Card.Title className='text-dark'>
                      O-LevelCheck
                    </Card.Title>
                    <Card.Text>
                      <ListGroup>
                        <ListGroupItem>
                          <p>
                            <span className='bold'>Candidate Name: </span>
                            <span>
                              {
                                check.CandidateName}
                            </span>
                          </p>
                        </ListGroupItem>
                        <ListGroupItem>
                          <p>
                            <span className='bold'>O-Level: </span>{' '}
                            <span>
                              <Link to={check.OLevelCert} >View File</Link>
                            </span>
                          </p>
                        </ListGroupItem>
                      </ListGroup>

                    </Card.Text>
                  </Card.Body>
                </Card>
              ) : check.type === "Identity" ? (
                <Card>
                  <Card.Body>
                    <Card.Title className='text-dark'>
                      Identity Check
                    </Card.Title>
                    <Card.Text>
                      <ListGroup>
                        <ListGroupItem>
                          <p>
                            <span className='bold'>Candidate Name: </span>
                            <span>
                              {
                                check.CandidateName}
                            </span>
                          </p>
                        </ListGroupItem>
                        <ListGroupItem>
                          <p>
                            <span className='bold'>Regulatory ID: </span>{' '}
                            <span>
                              <Link to={check.RegulatoryID} >View File</Link>
                            </span>
                          </p>
                        </ListGroupItem>
                      </ListGroup>

                    </Card.Text>
                  </Card.Body>
                </Card>
              ) : check.type === "Others" && (
                <Card>
                  <Card.Body>
                    <Card.Title className='text-dark'>
                      Miscellaneous Check
                    </Card.Title>
                    <Card.Text>
                      <ListGroup>
                        <ListGroupItem>
                          <p>
                            <span className='bold'>Candidate Name: </span>
                            <span>
                              {
                                check.CandidateName}
                            </span>
                          </p>
                        </ListGroupItem>
                        <ListGroupItem>
                          <p>
                            <span className='bold'>Certificate: </span>{' '}
                            <span>
                              <Link to={check.Certificate} >View File</Link>
                            </span>
                          </p>
                        </ListGroupItem>
                      </ListGroup>

                    </Card.Text>
                  </Card.Body>
                </Card>
              )}

            </Col>

            <Col xs={12} md={6}>

            </Col>
          </Row>

          <Row className='mt-5'>
            <Col xs={12} md={6}>

            </Col>

            <Col xs={12} md={6}>

            </Col>
            {userInfo.isAdmin && (<>
              <Col md={6} className='py-3'>
                {check.isPaid ? (<p> <span className='bold'>
                  Paid on
                </span> <span>:  <time>
                  <Moment format='ddd MMM D YYYY'>
                    {check.paidAt}
                  </Moment>
                </time></span></p>) : (<Button onClick={updateToPaid} variant='info'>Mark As Paid</Button>)}
              </Col>
              <Col md={6} className='py-3'>
                {check.isCompleted ? (<p> <span className='bold'>
                  Completed on
                </span> <span>:  <time>
                  <Moment format='ddd MMM D YYYY'>
                    {check.completedAt}
                  </Moment>
                </time></span></p>) : (<Button disabled={!check.isPaid} onClick={updateToCompleted} variant='success'>Mark As Completed</Button>)}
              </Col>
            </>)}

          </Row>

        </Fragment>
      )}
    </>
  );
};

export default CheckScreen;
